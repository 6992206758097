import { NgModule } from '@angular/core';
import { DataStewardRequestDetailsResolver } from './approval-request-details.resolver';
import { DataStewardQueueListComponent } from './containers/data-steward-queue-list/data-steward-queue-list.component';
import { DataStewardLogViewResolver } from './data-steward-log-view.resolver';
import { DataStewardQueueGuard } from './data-steward-queue.guard';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AccordionModule,
  AutoCompleteModule,
  CheckboxModule,
  DialogModule,
  DropdownModule,
  MessagesModule,
  PaginatorModule,
  SharedModule,
  TableModule,
  ToastModule,
  TooltipModule
} from 'primeng-lts';
import { DataStewardLogViewComponent } from './containers/data-steward-log-view/data-steward-log-view.component';
import { DataStewardRequestComponent } from './containers/data-steward-request/data-steward-request.component';


@NgModule({
  imports: [
    CommonModule,
    TableModule,
    SharedModule,
    AutoCompleteModule,
    PaginatorModule,
    DialogModule,
    AccordionModule,
    MessagesModule,
    ToastModule,
    DropdownModule,
    CheckboxModule,
    TooltipModule,
    RouterModule,
    ReactiveFormsModule
  ],
  declarations: [
    DataStewardQueueListComponent,
    DataStewardRequestComponent,
    DataStewardLogViewComponent
  ],
  providers: [
    DataStewardQueueGuard,
    DataStewardRequestDetailsResolver,
    DataStewardLogViewResolver
  ],
  exports: [

  ]
})
export class DataStewardQueueModule { }
