import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '@edw-app-root/permissions';
import { UserDataService } from '@edw-app-root/services/user-data.service';
import { IDataStewardRequest } from '@edw-entities/data-steward-queue';
import { DataStewardQueueService } from '@edw-services/data-steward-queue.service';
import {
  Table
} from 'primeng-lts';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'edw-data-steward-queue-list',
  templateUrl: './data-steward-queue-list.component.html',
  styleUrls: ['./data-steward-queue-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataStewardQueueListComponent implements OnInit, OnDestroy {
  public researchRequests: IDataStewardRequest[] = [];
  public searchField: string = "";
  public searchSub: Subscription;
  public limitViewBasedOnUser: boolean = true;
  public isLoading = false;

  @ViewChild('dataTable',{static: true}) dataTable : Table;

  constructor(
    private dataStewardQueueService: DataStewardQueueService,
    private userDataService: UserDataService,
    private route: ActivatedRoute
  ) {

    // if user can view all... do not limit their view (thus, we are negating the canViewAll value).
    this.limitViewBasedOnUser = this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canViewAll);

    this.isLoading = true;
    this.getDataStewardQueue("",1).pipe(
      map( res =>{
        if(res){ this.researchRequests = [...this.researchRequests, ...res]; } }
      ),
      map(() => this.isLoading = false )
    ).subscribe();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  private getDataStewardQueue = ( lookupValue, page: number = 1 ) => this.dataStewardQueueService.getDataStewardQueue(lookupValue,page,!this.limitViewBasedOnUser);

  public lazyLoadMore($event) {
    const lastPage = this.dataTable.totalRecords / this.dataTable.rows;

    const firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;

    if ($event.first === firstIndexOnLastPage) {
      // BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      this.getDataStewardQueue(this.searchField, Math.ceil(lastPage / 10) + 1 ).pipe(
        map( res =>{
          if(res){ this.researchRequests = [...this.researchRequests, ...res]; } }
        )
      ).subscribe()
    }
  }

  public clearSearch(autoCompleteRef) {
    this.searchField = "";
    // the next two lines are here because the autocomplete
    // component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }



  public search() {
    if(this.searchField.length == 0 || this.searchField.length > 2){
      return this.getDataStewardQueue( this.searchField, 1 ).pipe(
        map( res => this.researchRequests = res?.length > 0  ? [...res] : [] )
      ).subscribe();
    }
  }


}
